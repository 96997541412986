

footer {
    bottom: 0;
    background: #d3e9fd;
    color: black;
    /* width: 100vw; */
    max-width: 100vw;
    overflow-x: hidden;
    
  }

  .fh{
    color: #242424;
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 0.9px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .newsletter h3{
    color: #133682;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .newsletter span{
    color: #FD2621;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .top a{
    color: #262626;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: 400;
  }
  
  /* Bottom - copyright and legal stuff */
  
  footer .info {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d3e9fd;
    border-top: 1px solid rgba(19, 54, 130, 0.50);   
    
  }

  .cs p{
    color: #242424;
    font-size: 16px;
    letter-spacing: 0.7px;
    padding: 20px;
  }

  .cs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  footer .info .legal {
    display: flex;
    flex-wrap: nowrap;
  }
  
  footer .info .legal > a {
    margin-right: 15px;
  }
  
  /* Middle - social media links */
  
  footer .social {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
  }

  footer .top ul {
    list-style-type: none; /* Removes the dots */
          /* To align items horizontally */
    
  }



  
  
  footer .social i {
    font-size: 1.4rem;
    margin: 2px 8px;
    cursor: pointer;
   
  }
  
  footer .social:before {
    position: absolute;
    content: "";
    width: 90%;
    height: 1px;
    background: rgba(255, 255, 255, 0.192);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Top - pages, email newsletter form ... */
  
  footer .top {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction:row-reverse;
  }
  
  footer .top .pages {
    display: flex;
    flex-direction: row;
    gap:40px
  }
  
  footer .top .pages ul {

    margin: 16px;
  }
  
  footer .top .pages ul li {
  
    margin: 15px;
  }
  
  footer .newsletter h3 {
    margin-bottom: 10px;
  }
  footer .newsletter form {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copyright{
    margin-top: 10px; 
    font-size: 18px;
    color: rgba(10, 37, 64, 0.50);
    letter-spacing: 0.8px;
  }

  @media screen and (max-width: 1070px) {

    footer {
   
      width: 104vw; 
      max-width: 104vw;
      overflow-x: hidden;
     
   }

    footer .top {
        flex-direction: column-reverse;
      }
      
    .newsletter{
        margin-bottom: 40px;
    }

    
    footer .top .pages{
      gap:9px;
    }

  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {

    footer {
   
       width: 104vw; 
       max-width: 104vw;
      overflow-x: hidden;
      
    }

    footer .top .pages{
      gap:3px;
    }

    .newsletter{
        margin-bottom: 40px;
    }
    footer .top {
      flex-direction: column-reverse;
    }
    footer .top .newsletter {
      padding-top: 1rem;
    }
    footer .top .pages ul {
      margin: 10px;
    }
  }
  
  @media screen and (max-width: 600px) {

    
    footer {
   
      width: 104vw; 
      max-width: 104vw;
     overflow-x: hidden;
     
   }
    footer .top .pages {

      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  
    footer .info {
      flex-direction: column;
    }
  
    footer .info > .copyright {
      margin-top: 10px;

    }
  }
  
  @media screen and (max-width: 380px) {

    
    footer {
   
      width: 104vw; 
      max-width: 104vw;
     overflow-x: hidden;
     
   }
    footer .top .pages {
      justify-content: center;
      flex-direction: column;
    }
  
    footer .info .legal {
      justify-content: center;
    }
  
    footer .info .legal a {
      margin: 0;
      text-align: center;
    }
  }
  