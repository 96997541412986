/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.membership-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.membership-title {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0;
  text-align: center;
}

.membership {
  margin-top: 40px;
  margin-right: 120px;
}

.mb-cards {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.first-3,
.second-2 {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.first-3 {
  margin-left: 80px;
}

.second-2 {
  margin-left: 200px;
}

.bx1,
.bx2,
.bx3,
.bx4,
.bx5 {
  flex: 1;
  min-width: 403px;
  max-width: 403px;
  height: 475px;
  background: #fcfcfc;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 13.4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 2rem;
  font-family: Arial, sans-serif;
  position: relative;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.mb-img {
  width: 100px;
  height: 100px;
}

.bx-content {
  margin-top: 20px; /* Adjust this value to fine-tune the spacing */
  text-align: center; /* Center align the text within .bx-content */
}

.bx-content h1 {
  font-size: 36px; /* Increased font size */
  font-weight: 700;
  text-align: center; /* Center align the heading */
}

.bx-content p {
  font-size: 20px; /* Increased font size */
  font-weight: 600;
  text-align: left; /* Left align the paragraph content */
}

.bx-content span {
  font-weight: 400;
}

.bx-content ul {
  padding: 0;
  text-align: left; /* Left align the list items */
}

.bx-content li {
  font-size: 20px; /* Increased font size */
  font-weight: 400;
  margin-left: 40px;
}

/* Media Queries */
@media (max-width: 1232px) {
  .membership {
    width: 100%;
    height: auto;
    padding: 20px 10px;
  }

  .membership-title {
    font-size: 2rem;
  }

  .first-3,
  .second-2 {
    gap: 20px;
  }

  .bx1,
  .bx2,
  .bx3,
  .bx4,
  .bx5 {
    min-width: calc(50% - 20px);
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .membership {
    width: 100%;
    height: auto;
    margin: 50px auto;
    border: none;
    padding-top: 20px;
  }

  .membership-title {
    font-size: 1.8rem;
  }

  .first-3,
  .second-2 {
    gap: 10px;
  }

  .second-2 {
    margin-left: 80px;
  }

  .bx1,
  .bx2,
  .bx3,
  .bx4,
  .bx5 {
    margin-top: 30px;
    min-width: 100%;
    margin-bottom: 20px;
    margin-right: 75px;
  }
}

@media (max-width: 480px) {
  .membership-title {
    font-size: 1.5rem;
  }

  .bx-content h1 {
    font-size: 24px; /* Adjusted font size for smaller screens */
  }

  .second-2 {
    margin-left: 80px;
  }

  .bx1,
  .bx2,
  .bx3,
  .bx4,
  .bx5 {
    margin-top: 30px;
    min-width: 100%;
  }

  .bx-content p,
  .bx-content li {
    font-size: 16px; /* Adjusted font size for smaller screens */
  }
}
