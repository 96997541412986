.TeamSlider .slick-prev:before,
.TeamSlider .slick-next:before {
  color: #133680;
  font-size: 28px;
}

.TeamSlider .slick-prev{
  margin-left: 10%;
  z-index: 9000 !important
}

.TeamSlider .slick-next{
  margin-right: 10%;
}